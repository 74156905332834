.root{
    font-family: 'PT Sans', sans-serif;
}

.servicesBlock-container {
    background-color: #242424;
    padding: 2rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .services-heading{   
    color:black;
  }

  .services-block{ 
    color:#242424d7;
}

p{
    margin-top:1%;
}
  
.services-card {
    margin-bottom: 2rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    outline: none;
    border: 1px solid white;
    box-shadow: 0 6px 20px rgba(68, 71, 71, 0.17);
  }
  
.services-card-text{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
}

@media screen and (max-width: 768px) {
    .services-card{
        flex-direction: column;
    }
    
}