.textBlock-container {
    background-color: #fff;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  h1 {
    text-align: center;
  }

  h2 {
    text-align: center;
  }

  .textBlock1 {
    margin: 0 6rem;
    color: #505050;
  }

  @media screen and (max-width: 960px) {
    .textBlock1 {
      margin: 0 1rem;
    }
  }

  .textBlock2 {
    color: #242424;
    padding: 2rem 1rem;
  }

  .img-halifax {
    color: #242424;
    width: 60%;
    padding: 2rem 0;
  }