a { color: inherit; }

.form-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.form-item {
  display: flex;
  flex-flow: column;
  padding: 1rem;
  align-items: center;
}

.formItself{
  display: flex;
  flex-flow: column;
  align-items: left;
  justify-content: center;
}

.contactFields{
  display: flex;
  flex-flow: column;
  align-items: left;
  justify-content: left;
}

.inputField-form{
  padding: 0.2rem 0;
}

input[type=text], input[type=email], textArea{
  width: 100%;
  padding: 12px 20px;
  margin: 0;
  box-sizing: border-box;
}


.form-wrapper {
  color:white;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding:1rem;
  background-color: #242424;
  border-radius: 10px;
}

.contactUsSection {
  color:white;
  display: flex;
  flex-flow: column;
  padding: 1rem;
  align-items: center;
  
}

.contactRow{
  display: flex;
  flex-flow: row;
  margin:1rem;
}

.contactIcon{
  padding: 0 1rem;
}
.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
}

@media screen and (max-width: 768px) {
  .form-wrapper{
      flex-direction: column;
  }
  
}